// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.balancesheet-container {
  display: flex; /* Use flexbox to arrange items */
  flex-direction: column; /* Arrange the components in a column (vertically) */
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  overflow-x: auto; /* Enables horizontal scrolling when content overflows */
  max-width: 100%; /* Ensures the container width doesn't exceed the viewport */
}

.asset-liability-container {
  padding-top: 10px;
  max-width: 100%;
}

.header {
  font-weight: bold;
}

.row {
  display: flex; /* Keep the header items in a single row */
  flex-wrap: nowrap; /* Prevent wrapping within the row */
  max-width: 100%;
}

.common-item {
  text-align: center;
  border-left: 1px solid #ddd;
  flex: 0 0 150px;
}

/* Specific styles */
.header-item {
  background-color: #e3e3e3; /* Specific to header items */
  /* Other styles specific to header-item can be added here */
}

.balance-sheet-item {
  /* Styles specific to balance-sheet-item can be added here */
}

.highlight-header {
  background-color: #ffd700;
  font-weight: bold;
  border: 2px solid #000;
}

.highlight-data {
  background-color: #fff2a5;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
}
`, "",{"version":3,"sources":["webpack://./src/components/funds/BalanceSheet.css"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE,iCAAiC;EAChD,sBAAsB,EAAE,oDAAoD;EAC5E,iBAAiB;EACjB,oBAAoB;EACpB,sBAAsB;EACtB,yBAAyB;EACzB,gBAAgB,EAAE,wDAAwD;EAC1E,eAAe,EAAE,4DAA4D;AAC/E;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa,EAAE,0CAA0C;EACzD,iBAAiB,EAAE,oCAAoC;EACvD,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B,eAAe;AACjB;;AAEA,oBAAoB;AACpB;EACE,yBAAyB,EAAE,6BAA6B;EACxD,2DAA2D;AAC7D;;AAEA;EACE,4DAA4D;AAC9D;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,4BAA4B;AAC9B","sourcesContent":[".balancesheet-container {\n  display: flex; /* Use flexbox to arrange items */\n  flex-direction: column; /* Arrange the components in a column (vertically) */\n  padding-top: 10px;\n  padding-bottom: 10px;\n  border: 1px solid #ccc;\n  background-color: #f9f9f9;\n  overflow-x: auto; /* Enables horizontal scrolling when content overflows */\n  max-width: 100%; /* Ensures the container width doesn't exceed the viewport */\n}\n\n.asset-liability-container {\n  padding-top: 10px;\n  max-width: 100%;\n}\n\n.header {\n  font-weight: bold;\n}\n\n.row {\n  display: flex; /* Keep the header items in a single row */\n  flex-wrap: nowrap; /* Prevent wrapping within the row */\n  max-width: 100%;\n}\n\n.common-item {\n  text-align: center;\n  border-left: 1px solid #ddd;\n  flex: 0 0 150px;\n}\n\n/* Specific styles */\n.header-item {\n  background-color: #e3e3e3; /* Specific to header items */\n  /* Other styles specific to header-item can be added here */\n}\n\n.balance-sheet-item {\n  /* Styles specific to balance-sheet-item can be added here */\n}\n\n.highlight-header {\n  background-color: #ffd700;\n  font-weight: bold;\n  border: 2px solid #000;\n}\n\n.highlight-data {\n  background-color: #fff2a5;\n  border-left: 2px solid #000;\n  border-right: 2px solid #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
