// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-info-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.user-details {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.user-details p {
  margin: 8px 0;
}

.user-details strong {
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/components/userInfo/UserInfo.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb","sourcesContent":[".user-info-container {\n  padding: 20px;\n  font-family: Arial, sans-serif;\n}\n\n.user-details {\n  border: 1px solid #ddd;\n  padding: 15px;\n  border-radius: 8px;\n  background-color: #f9f9f9;\n}\n\n.user-details p {\n  margin: 8px 0;\n}\n\n.user-details strong {\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
