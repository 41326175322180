import { useState, useEffect } from 'react';

import axios from 'axios';
import { base_url } from '../../../environment/url';

const serverHealth_debugThis = false;
const what = 'serverHealth';

const useServerHealth = (pollInterval = 60000) => {
  const [isServerAlive, setIsServerAlive] = useState(true); // Default to assume the server is alive

  const checkServerStatus = async () => {
    const debugThis = false || serverHealth_debugThis;
    const msgPrefix = what + '.checkServerStatus'
    try {
      const url = base_url + '/system/health';

      const response = await axios.get(url);
      if (debugThis) {
        console.log(msgPrefix, JSON.stringify(response));
      }

      setIsServerAlive(response.status); // Server is alive if status is 200-299
    } catch (error) {
      setIsServerAlive(false); // Server is unreachable
    }
  };

  useEffect(() => {
    // Immediately check server status when the hook initializes
    checkServerStatus();

    // Set up periodic polling
    const interval = setInterval(checkServerStatus, pollInterval);
    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [pollInterval]);

  return isServerAlive;
};

export default useServerHealth;
