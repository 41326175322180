import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';

const what = 'fundUtils'
const fundUtils_all_debugThis = false

const fetchFundManager = async (setFundManagers, caller_debugThis) => {
  const msgPrefix = what + '.fetchFundManager';
  const debugThis = false || fundUtils_all_debugThis || caller_debugThis;

  try {
    const url = base_url + '/funds/manager';

    if (debugThis) {
      console.log(msgPrefix, 'URL', url);
    }

    const response = await axios.get(url);

    if (debugThis) {
      console.log(msgPrefix, 'response docs', JSON.stringify(response.data.data.docs));
    }

    setFundManagers(response.data.data.docs);
  } catch (error) {
    console.error(msgPrefix, 'Error', error);
  }
};

const userIsFundManager = (fundManagers, userID, caller_debugThis) => {
  const msgPrefix = what + '.userIsFundManager';
  const debugThis = false || fundUtils_all_debugThis || caller_debugThis;

  if (debugThis) {
    console.log(msgPrefix, 'fundManagers', JSON.stringify(fundManagers), 'userID', JSON.stringify(userID));
  }

  return fundManagers.some((manager) => manager._id === userID);
};

export default { fetchFundManager, userIsFundManager };
