import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useAppContext } from '../context/appContextProvider';
import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';

import '../../global.css';
import './FundsDetail.css';

import FiscalYear from '../common/FiscalYear';
import FiscalCloseYearButton from '../common/FiscalCloseYearButton';

import FundsLedger from './FundsLedger';
import FundActions from './FundActions';
import BalanceSheet from './BalanceSheet';
import IncomeStatement from './IncomeStatement';
import fundUtils from './fundUtils';

const fundsDetail_debugThis = false;
const what = 'fundsDetails';

const FundsDetail = () => {
  const { userID } = useAppContext();
  const { id } = useParams();
  const [details, setDetails] = useState([]);
  const [fundManagers, setFundManagers] = useState([]); // State for fund managers

  const [actionsVisible, setActionsVisible] = useState(false);
  const [ledgerVisible, setLedgerVisible] = useState(true);
  const [balanceSheetVisible, setBalanceSheetVisible] = useState(false);
  const [incomeStatementVisible, setIncomeStatementVisible] = useState(false);

  const [fiscalYear, setFiscalYear] = useState(new Date().getFullYear());

  const fetchFundDetails = useCallback(async () => {
    const debugThis = false || fundsDetail_debugThis;
    const msgPrefix = what + '.fetchFundDetails';

    try {
      const url = base_url + `/funds/id/${id}`;

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.get(url);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      setDetails(response.data);
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  }, [setDetails, id]);

  // component mounting
  useEffect(() => {
    const fetchData = async () => {
      await fundUtils.fetchFundManager(setFundManagers, fundsDetail_debugThis); // Call the utility function
      await fetchFundDetails();
    };

    fetchData();
  }, [fetchFundDetails]);

  const closeFiscalYear = useCallback(async () => {
    const debugThis = false || fundsDetail_debugThis;
    const msgPrefix = what + '.closeFiscalYear';

    try {
      const url = base_url + `/funds/${id}/closefiscalyear`;

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      let body = {
        fundManagerUserID: userID,
      };

      const response = await axios.post(url, body);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  }, [id, userID]);

  const toggleActionsVisibility = () => {
    setActionsVisible((prevVisible) => !prevVisible);
  };

  const toggleLedgerVisibility = () => {
    setLedgerVisible((prevVisible) => !prevVisible);
  };

  const toggleBalanceSheetVisibility = () => {
    setBalanceSheetVisible((prevVisible) => !prevVisible);
  };

  const toggleIncomeStatementVisibility = () => {
    setIncomeStatementVisible((prevVisible) => !prevVisible);
  };

  const handleYearChange = (event) => {
    setFiscalYear(event.target.value);
  };

  const closeFiscalYearHandler = () => {
    const debugThis = false || fundsDetail_debugThis;
    const msgPrefix = what + '.closeFiscalYearHandler';
    if (debugThis) {
      console.log(msgPrefix, 'closing fiscal year', id);
    }

    closeFiscalYear();

    fetchFundDetails();
  };

  return (
    <div className="funds-detail-container">
      <h1 className="funds-detail-title">FUND: {details.name}</h1>

      {fundUtils.userIsFundManager(fundManagers, userID, fundsDetail_debugThis) && (
        <>
          <div className="ledger-toggle" onClick={toggleActionsVisibility}>
            <span className={`caret ${actionsVisible ? 'rotate' : ''}`}>▼</span>
            {actionsVisible ? 'Hide Bookkeeping' : 'Show Bookkeeping'}
          </div>
          {actionsVisible && <FundActions details={details} onSubmit={fetchFundDetails} />}
        </>
      )}

      <div className="fiscalContainer">
        <div className="fiscalRow">
          <FiscalYear fiscalYear={fiscalYear} details={details} handleYearChange={handleYearChange} />
          <FiscalCloseYearButton fiscalYear={fiscalYear} details={details} closeFiscalYear={closeFiscalYearHandler} />
        </div>
      </div>

      <div className="ledger-toggle" onClick={toggleLedgerVisibility}>
        <span className={`caret ${ledgerVisible ? 'rotate' : ''}`}>▼</span>
        {ledgerVisible ? 'Hide Ledger' : 'Show Ledger'}
      </div>
      {ledgerVisible && <FundsLedger fiscalYear={fiscalYear} details={details} />}

      <div className="ledger-toggle" onClick={toggleBalanceSheetVisibility}>
        <span className={`caret ${balanceSheetVisible ? 'rotate' : ''}`}>▼</span>
        {balanceSheetVisible ? 'Hide Balance Sheet' : 'Show Balance Sheet'}
      </div>
      {balanceSheetVisible && <BalanceSheet fiscalYear={fiscalYear} />}

      <div className="ledger-toggle" onClick={toggleIncomeStatementVisibility}>
        <span className={`caret ${incomeStatementVisible ? 'rotate' : ''}`}>▼</span>
        {incomeStatementVisible ? 'Hide Income Statement' : 'Show Income Statement'}
      </div>
      {incomeStatementVisible && <IncomeStatement fiscalYear={fiscalYear} details={details} />}
    </div>
  );
};

export default FundsDetail;
