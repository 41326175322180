import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';

import '../../global.css';
import './BalanceSheet.css';

import BalanceSheetHeader from './BalanceSheetHeader';
import BalanceSheetAssets from './BalanceSheetAssets';
import BalanceSheetLiabilities from './BalanceSheetLiabilities';
import BalanceSheetTotals from './BalanceSheetTotals';

const balanceSheet_debugThis = false;
const what = 'balanceSheet';

const BalanceSheet = ({ fiscalYear }) => {
  const debugThis = balanceSheet_debugThis || false;
  const msgPrefix = what;

  const { id } = useParams();
  const [balanceSheetData, setBalanceSheetData] = useState([]);

  if (debugThis) {
    console.log(msgPrefix, 'id', JSON.stringify(id));
  }

  const fetchBalanceSheetData = useCallback(async () => {
    const debugThis = false || balanceSheet_debugThis;
    const msgPrefix = what + '.fetchBalanceSheetData';
    try {
      const url = base_url + `/funds/${id}/balancesheet`;

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.get(url);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      setBalanceSheetData(response.data);
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  }, [setBalanceSheetData]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchBalanceSheetData();
    };

    fetchData();
  }, [fetchBalanceSheetData]);

  if (!balanceSheetData || !balanceSheetData.fiscalYearTotals || balanceSheetData.fiscalYearTotals.length === 0) {
    return <p>No data available</p>;
  }

  return (
    <div className="balancesheet-container">
      <BalanceSheetHeader balanceSheetData={balanceSheetData} fiscalYear={fiscalYear} />
      <BalanceSheetAssets balanceSheetData={balanceSheetData} fiscalYear={fiscalYear} />
      <BalanceSheetLiabilities balanceSheetData={balanceSheetData} fiscalYear={fiscalYear} />
      <BalanceSheetTotals balanceSheetData={balanceSheetData} fiscalYear={fiscalYear} />
    </div>
  );
};

export default BalanceSheet;
