import '../../global.css';
import './SuggestionList.css';

import SuggestionAccordion from './SuggestionAccordion';

const what = 'suggestionList';
const suggestionList_debugThis = false;

const SuggestionList = ({ suggestions, onRefreshList }) => {
  const debugThis = false || suggestionList_debugThis;
  const msgPrefix = what;

  if (debugThis) {
    console.log(msgPrefix, JSON.stringify(suggestions));
  }

  return (
    <div className="suggestion-list">
      {suggestions.map((suggestion) => (
        <SuggestionAccordion key={suggestion._id} suggestion={suggestion} onRefreshList={onRefreshList} />
      ))}
    </div>
  );
};

export default SuggestionList;
