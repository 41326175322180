import './about.css';

import { paypal_donate } from '../../environment/keys';

const About = () => {
  const data = [{ year: '2024', domain: 1.23, git: 4.0, hosting: 14.0, email: 7.99 }];

  return (
    <div className="about-container">
      <h1>About</h1>

      <div className="about-creation-container">
        <h2>Creation</h2>
        <p>The site was created on 30 Jul 2024</p>
      </div>

      <div className="about-maintained-container">
        <h2>Maintenance</h2>
        <p>Big Fir Estates website is maintained by Mike Liss</p>
      </div>

      <div className="monthly-cost-container">
        <h2>Monthly Costs</h2>
        <p>Domain name registration, storing source code and hosting a site is not free. The custom programming is.</p>
        <table>
          <thead>
            <tr>
              <th style={{ width: '80px' }}>Year</th>
              <th style={{ width: '90px' }}>Domain</th>
              <th style={{ width: '90px' }}>Git Hub</th>
              <th style={{ width: '90px' }}>Hosting</th>
              <th style={{ width: '90px' }}>Email</th>
              <th style={{ width: '100px' }}>Total</th>
              <th style={{ width: '110px', color: 'rgba(255, 0, 0, 1.0)' }}>Total per LOT</th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, key) => {
              return (
                <tr key={key}>
                  <td>{val.year}</td>
                  <td>${(Math.round(val.domain * 100) / 100).toFixed(2)}</td>
                  <td>${(Math.round(val.git * 100) / 100).toFixed(2)}</td>
                  <td>${(Math.round(val.hosting * 100) / 100).toFixed(2)}</td>
                  <td>${(Math.round(val.email * 100) / 100).toFixed(2)}</td>
                  <td>${(Math.round((val.domain + val.git + val.hosting + val.email) * 100) / 100).toFixed(2)}</td>
                  <td>
                    ${(Math.round(((val.domain + val.git + val.hosting + val.email) / 30) * 100) / 100).toFixed(2)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="about-donation-container">
        <h2>Donations</h2>
        <p>Running Big Fir Estates is a labor of love, and I'm dedicated to provide custom coding. </p>
        <p>However, maintaining the site involves the above costs and I could use your help.</p>
        <p>If you have found the content to be valuable, please consider making a donation.</p>
        <p>
          You can donate via the PayPal donate button below. ( no PayPal account required if you use a credit card )
        </p>

        <div>
          <form action="https://www.paypal.com/donate" method="post" target="_blank">
            <input type="hidden" name="business" value={paypal_donate} />
            <input type="hidden" name="no_recurring" value="0" />
            <input type="hidden" name="item_name" value="Big Fir Estates Web Site Maintenance" />
            <input type="hidden" name="currency_code" value="USD" />
            <input
              type="image"
              src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Donate with PayPal button"
            />
            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            <br />
            <br />
          </form>
        </div>
        <p>Thank you for your support! </p>
      </div>
    </div>
  );
};

export default About;
