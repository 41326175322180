import React from 'react';

import './FundManagerDisplay.css';

const what = 'fundManagerDisplay';
const fundManagerDisplay_debugThis = false;

const FundManagersDisplay = ({ fundManagers }) => {
  const debugThis = false || fundManagerDisplay_debugThis;
  const msgPrefix = what;

  if (!fundManagers) {
    if (debugThis) {
      console.log(msgPrefix, 'NO FUND MANAGERS');
    }

    return <></>;
  }
  const len = fundManagers.length;
  const label = len > 1 ? 'Fund Managers' : 'Fund Manager';

  if (debugThis) {
    console.log(msgPrefix, 'fundManagers', JSON.stringify(fundManagers));
  }

  return (
    <div className="fundsManagerContainer">
      {len > 0 ? (
        <>
          <h3>{label}</h3>
          <p>{fundManagers.map((manager) => `${manager.fName} ${manager.lName}`).join(', ')}</p>
        </>
      ) : (
        <p>No manager available</p>
      )}
    </div>
  );
};

export default FundManagersDisplay;
