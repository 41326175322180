import './BalanceSheet.css';

const balanceSheetHeader_debugThis = false;
const what = 'balanceSheetHeader';

const BalanceSheetHeader = ({ balanceSheetData, fiscalYear }) => {
  const debugThis = balanceSheetHeader_debugThis || false;
  const msgPrefix = what;

  // Extract fiscal years dynamically
  const fiscalYears = balanceSheetData?.fiscalYearTotals
    ? balanceSheetData.fiscalYearTotals.map((item) => item.fiscalYear).sort((a, b) => b - a)
    : [];

  if (debugThis) {
    console.log(
      msgPrefix,
      'fiscalYear',
      JSON.stringify(fiscalYear),
      'balanceSheetData',
      JSON.stringify(balanceSheetData),
      'fiscalYears',
      JSON.stringify(fiscalYears)
    );
  }

  const getHighLightClass = (year, fiscalYear) => {
    return Number(year) === Number(fiscalYear) ? 'highlight-header' : '';
  };

  return (
    <div className="row header">
      <div className="header-item label common-item" />
      {fiscalYears.map((year) => (
        <div key={year} className={`header-item common-item ${getHighLightClass(year, fiscalYear)}`}>
          {year}
        </div>
      ))}
    </div>
  );
};

export default BalanceSheetHeader;
