import './BalanceSheet.css';

const balanceSheetAssets_debugThis = false;
const what = 'balanceSheetAssets';

const BalanceSheetAssets = ({ balanceSheetData, fiscalYear }) => {
  const debugThis = balanceSheetAssets_debugThis || false;
  const msgPrefix = what;

  // Extract fiscal years and totalPayments dynamically
  const fiscalTotals = balanceSheetData?.fiscalYearTotals
    ? balanceSheetData.fiscalYearTotals
        .map((item) => ({
          year: item.fiscalYear,
          totalPayments: item.totalPayments,
        }))
        .sort((a, b) => b.year - a.year)
    : [];

  if (debugThis) {
    console.log(
      msgPrefix,
      'fiscalYear',
      JSON.stringify(fiscalYear),
      'balanceSheetData',
      JSON.stringify(balanceSheetData),
      'fiscalTotals',
      JSON.stringify(fiscalTotals)
    );
  }

  const getHighLightClass = (year, fiscalYear) => {
    return Number(year) === Number(fiscalYear) ? 'highlight-data' : '';
  };

  return (
    <div className="asset-liability-container">
      <div className="row">
        <div className="header-item common-item">Income</div>
        {fiscalTotals.map((item) => (
          <div key={item.year} className={`balance-sheet-item common-item ${getHighLightClass(item.year, fiscalYear)}`}>
            {item.totalPayments}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BalanceSheetAssets;

