// this file was created by following these steps:
//
// https://www.mensurdurakovic.com/create-selectable-image-sections-in-react/
//

import React from 'react';
import './plots2.css';

import Plot from './plot';

const plots2_debugThis = false;
const what = 'plots2';

const Plots2 = ({ onClickHandler, plots, selectedPlot }) => {
  const debugThis = false || plots2_debugThis;
  const msgPrefix = what;

  if (debugThis) {
    console.log(msgPrefix, JSON.stringify(selectedPlot));
  }

  return (
    <div className="plots-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="svg3"
        preserveAspectRatio="xMidYMid meet"
        version="1"
        viewBox="0 0 556 553"
      >
        <g id="g3" fill="#ffffff" stroke="#000" strokeWidth="1">
          {plots.map((plot) => (
            <Plot key={plot._id} plot={plot} onClickHandler={onClickHandler} selectedPlot={selectedPlot} />
          ))}
        </g>
      </svg>
    </div>
  );
};

export default Plots2;
