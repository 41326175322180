import React from 'react';

const COLORS_CONFIG = {
  default: 'white',
  selected: 'yellow',
  owned: '#75a5da',
};

const plot_debugThis = false;
const what = 'plot';

const Plot = ({ plot, onClickHandler, selectedPlot }) => {
  const isSelected = () => {
    return selectedPlot === plot;
  };

  const hasOwner = () => {
    return plot.owners && Array.isArray(plot.owners) && plot.owners.length !== 0;
  };

  const handleClick = () => {
    // Call the parent's click handler
    onClickHandler(plot);
  };

  const getFillColor = () => {
    const debugThis = false || plot_debugThis;
    const msgPrefix = what + '.getFillColor';

    let fillColor = COLORS_CONFIG.default;

    if (isSelected()) {
      fillColor = COLORS_CONFIG.selected;
    } else if (hasOwner()) {
      fillColor = COLORS_CONFIG.owned;
    }

    if (debugThis) {
      console.log(msgPrefix, plot.name, JSON.stringify(fillColor));
    }

    return fillColor;
  };

  const getPlotOwnerList = () => {
    const debugThis = false || plot_debugThis;
    const msgPrefix = what + '.getPlotOwnerList';

    if (debugThis) {
      console.log(msgPrefix, JSON.stringify(plot));
    }

    if (hasOwner()) {
      // Map through the users array to get the last names (or full names if desired)
      const periods = plot.owners.map(() => '■');

      return periods.join(' ');
    }

    return plot.name;
  };

  return (
    <>
      <path id={plot._id} onClick={handleClick} fill={getFillColor()} strokeWidth="0.75" d={plot.d} />
      <text
        x={plot.x}
        y={plot.y}
        textAnchor="middle"
        alignmentBaseline="middle"
        fontSize="14"
        fill="black"
        onClick={handleClick}
      >
        {plot.number}
      </text>
      <text
        x={plot.xname}
        y={plot.yname}
        textAnchor="middle"
        alignmentBaseline="middle"
        fontSize="12"
        fill="black"
        onClick={handleClick}
      >
        {getPlotOwnerList()}
      </text>
    </>
  );
};

export default Plot;
