import { useEffect, useState } from 'react';
import { base_url } from '../../environment/url';
import axios from '../axiosApi/axiosApi';
import { useAppContext } from '../context/appContextProvider';
import './UserInfo.css';

const userInfo_debugThis = true;
const what = 'userInfo';

const UserInfo = () => {
  const { userID } = useAppContext();
  const [userData, setUserData] = useState(null); // State to hold the user data

  const fetchData = async () => {
    const debugThis = userInfo_debugThis || false;
    const msgPrefix = what + '.fetchData';

    try {
      const url = `${base_url}/users/${userID}`;

      if (debugThis) {
        console.log(msgPrefix, 'URL', url);
      }

      const response = await axios.get(url);

      if (debugThis) {
        console.log(msgPrefix, 'response', JSON.stringify(response.data));
      }

      // Extract the user data from docs[0]
      const user = response.data.docs[0];
      setUserData(user);
    } catch (error) {
      console.error(msgPrefix, 'Error', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userID]); // Dependency to refetch if userID changes

  return (
    <div className="user-info-container">
      <h1>User Info</h1>
      {userData ? (
        <div className="user-details">
          <p>
            <strong>Name:</strong> {userData.fName} {userData.lName}
          </p>
          <p>
            <strong>Email:</strong> {userData.email}
          </p>
          <p>
            <strong>Phone:</strong> {userData.phone}
          </p>
          <p>
            <strong>Active:</strong> {userData.active ? 'Yes' : 'No'}
          </p>
          <p>
            <strong>User Type:</strong> {userData.usertype.Text}
          </p>
          <p>
            <strong>Date Created:</strong> {new Date(userData.dateCreated).toLocaleString()}
          </p>
        </div>
      ) : (
        <p>Loading user information...</p>
      )}
    </div>
  );
};

export default UserInfo;
